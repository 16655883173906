<style scoped>
.lable-title{
    width: 80px;
    display: inline-block;
}
</style>

<template>
    <div>
       <Tabs>
        <TabPane label="任务详情">
        <div class="text-center workplatform-title">
            通用审批
        </div>
         <div class="workplatform-title">基础信息</div>
         <Row class="p-b-5" :gutter="8">
                <i-col span="8" style="word-break: break-all;">
                    <label class="title">名称 </label>
                    {{besicInfo.name}}
                </i-col>
                <i-col span="8">
                    <label class="title">状态 </label>
                    {{besicInfo.statusName}}
                </i-col>
                <i-col span="8">
                    <label class="title">发起人 </label>
                    {{besicInfo.userName}}
                </i-col>
                 <i-col span="8">
                    <label class="title">备注 </label>
                    {{besicInfo.desc}}
                </i-col>
                <i-col span="8">
                    <label class="title">提交时间 </label>
                    {{besicInfo.createTime}}
                </i-col>
                 <i-col span="24">
                    <label class="title">描述 </label>
                    {{besicInfo.content}}
                </i-col>
            </Row>

         <div class="workplatform-title">上传文件清单</div>
        <i-table stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </TabPane>
        <TabPane label="任务进度" >
          <history :type="13" :relate-id="approvalId" />
        </TabPane>
      </Tabs>
    </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { formatApprovalResult } from '@/utils/tagStatus'
import { getApprovalById, fileByApprovalId } from '@/api/sys/approval'
import History from '@/components/approval/History'

export default {
  components: {
    History
  },
  data () {
    return {
      besicInfo: {},
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              '下载'
            )
          }
        }
      ],
      records: [],
      titles: [
        { title: '审批人', key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            return formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName)
          }
        },
        { title: '审批备注', key: 'comment' }
      ],
      approvalId: this.$store.state.approvalCreate.detailId
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { approvalId: this.approvalId }
      getApprovalById(query).then(res => {
        this.besicInfo = res
      })

      fileByApprovalId(query).then(res => {
        this.approvalFile = res
      })
    },
    getStatusColor (result) {
      let color = 'Gray'
      if (result === 4) { // 待审批
        color = 'Orange'
      } else if (result === 5) { // 已通过
        color = 'Black'
      } else if (result === 6) { // 已拒绝
        color = 'Red'
      }

      return color
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/approval/downloadfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
